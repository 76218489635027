import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  suggest(event) {
    event.preventDefault();
    document.getElementById("already_taken").classList.add("hidden");
    document.getElementById("is_available").classList.add("hidden");

    const value = event.currentTarget.textContent;
    const element = document.getElementById('user_username');
    element.value = value;
    element.focus();
    element.blur(); // This will remove focus from the element
  }
}
