if (typeof window !== "undefined") {
  // Handler to call on window resize
  function handleResize() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  // Add event listener
  window.addEventListener("resize", handleResize);

  // Call handler right away so state gets updated with initial window size
  handleResize();
}
